export enum Theme {
    DATE = 'DATING',
    BIZZ = 'BIZZ',
    BFF = 'BFF',
    NONE = 'NONE',
}

export const mapThemeToClassNames = {
    [Theme.DATE]: 'theme-dating',
    [Theme.BFF]: 'theme-bff',
    [Theme.BIZZ]: 'theme-bizz',
    [Theme.BIZZ]: 'theme-bizz',
    [Theme.NONE]: '',
} as const;
