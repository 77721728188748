import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Link from 'components/link/link';

type Props = {
    logo?: React.ReactNode;
    logoUrl?: string;
    headerMenu?: React.ReactNode;
    actionDesktopButtons?: React.ReactNode;
    actionMobilePanel?: React.ReactNode;
    actionAppDownload?: React.ReactNode;
    actionIosAppDownload?: React.ReactNode;
    actionAndroidAppDownload?: React.ReactNode;
    actionSignIn?: React.ReactNode;
    actions?: React.ReactNode[];
    extraClass?: string;
};

// images/-/moxie/new/js/components/header.js
function Header(props: Props) {
    const {
        logo,
        logoUrl,
        headerMenu,
        actionDesktopButtons,
        actionMobilePanel,
        actionAppDownload,
        actionIosAppDownload,
        actionAndroidAppDownload,
        actionSignIn,
        actions,
        extraClass,
    } = props;

    const [isVisible, setVisible] = useState(true);
    const [isFixed, setIsFixed] = useState(false);

    const headerClassName = cx({
        'is-hidden': !isVisible,
        'is-fixed': isFixed,
        header__inner: true,
        [extraClass as string]: extraClass,
    });

    useEffect(() => {
        const ieBrowser =
            global.document.body.classList.contains('ie11') ||
            global.document.body.classList.contains('edge');

        let lastScrollTop = 0;

        function update() {
            if (
                global.document.documentElement.clientWidth < 600 ||
                global.document.documentElement.clientHeight < 600
            ) {
                const currentScrollTop = Math.max(global.window.scrollY, 0);
                setIsFixed(true);

                if (Math.abs(lastScrollTop - currentScrollTop) <= 5) {
                    return;
                }

                if (currentScrollTop > lastScrollTop) {
                    setVisible(false);
                } else if (
                    currentScrollTop + global.window.innerHeight <
                    global.document.documentElement.scrollHeight
                ) {
                    setVisible(true);
                }

                lastScrollTop = currentScrollTop;
            } else {
                setVisible(true);
                setIsFixed(false);
            }
        }

        const throttledUpdate = throttle(update, 300);

        if (ieBrowser === false) {
            global.window.addEventListener('scroll', throttledUpdate);
            global.window.addEventListener('resize', throttledUpdate);
        }

        return () => {
            global.window.removeEventListener('scroll', throttledUpdate);
            global.window.removeEventListener('resize', throttledUpdate);
        };
    }, []);

    return (
        <div className="header">
            <div className={headerClassName}>
                <div className="header__logotype">
                    <Link className="header__logotype-link" href={logoUrl} aria-label="Home page">
                        {logo}
                    </Link>
                </div>
                {headerMenu ? <div className="header__menu">{headerMenu}</div> : null}
                <div className="header__actions">
                    {actionMobilePanel ? (
                        <div className="header__actions-item header__actions-item--mobile-panel">
                            {actionMobilePanel}
                        </div>
                    ) : null}

                    {actionAppDownload ? (
                        <div className="header__actions-item header__actions-item--download">
                            {actionAppDownload}
                        </div>
                    ) : null}

                    {actionIosAppDownload ? (
                        <div className="header__actions-item header__actions-item--download-ios">
                            {actionIosAppDownload}
                        </div>
                    ) : null}

                    {actionAndroidAppDownload ? (
                        <div className="header__actions-item header__actions-item--download-android">
                            {actionAndroidAppDownload}
                        </div>
                    ) : null}

                    {actionDesktopButtons ? (
                        <div className="header__actions-item header__actions-item--dw-buttons">
                            {actionDesktopButtons}
                        </div>
                    ) : null}

                    {actionSignIn ? (
                        <div className="header__actions-item header__actions-item--sign-in">
                            {actionSignIn}
                        </div>
                    ) : null}

                    {actions
                        ? actions.map((action, index) => (
                              <div className="header__actions-item" key={index}>
                                  {action}
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </div>
    );
}

export default Header;

function throttle(func: any, wait: any) {
    let timeout: any;
    return function () {
        if (timeout) {
            return;
        }

        timeout = setTimeout(function () {
            timeout = undefined;
            func();
        }, wait);
    };
}
