import React from 'react';
import cx from 'classnames';
import { Theme, mapThemeToClassNames } from 'ui/themes';

type Props = {
    children?: React.ReactNode;
    isSplashScreen?: boolean;
    hasInner?: boolean;
    hasNav?: boolean;
    theme?: Theme;
    extraClass?: string;
    jsClass?: string;
    isFullWidth?: boolean;
};

function PageSection(props: Props) {
    const {
        children,
        isSplashScreen,
        hasInner,
        hasNav,
        theme = Theme.DATE,
        isFullWidth,
        extraClass,
        jsClass,
    } = props;

    const className = cx({
        page__section: true,
        'page__section--splash-screen': isSplashScreen,
        'page__section--categories-menu': hasNav,
        'page__section--full-width': isFullWidth,
        [mapThemeToClassNames[theme]]: true,
        [extraClass as string]: extraClass,
        [jsClass as string]: jsClass,
    });

    const PageSectionTag = hasNav ? 'nav' : 'section';

    const content =
        !isSplashScreen && !hasInner ? (
            children
        ) : (
            <div className="page__section-inner">{children}</div>
        );

    return <PageSectionTag className={className}>{content}</PageSectionTag>;
}

PageSection.Theme = Theme;

export default PageSection;
